import React, {useEffect, useState} from 'react';
import axios from "axios";
import BASEURL from "../../../Constants";
import {ToastContainer} from "react-toastify";
import {Link, useHistory} from "react-router-dom";

const ServiceProviderList = () => {
    const [serviceProvider, setServiceProvider] = useState([])
    const getServiceProviderList = () => {
        axios.get(BASEURL+'/api/client/get-service-provider-list').then(res=>{
            setServiceProvider(res.data)
        })
    }

    useEffect(()=>{
        getServiceProviderList()
    }, [])

    return (
        <main>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container-fluid px-4">
                <h1 className="mt-4">Service Provider List</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item"><Link to={'/'}>Dashboard</Link></li>
                    <li className="breadcrumb-item active">Service Provider List</li>
                </ol>
                <div className="my-card">
                    <div className="row">
                        <div className="col-md-12">

                            <table className={'table table-hover table-bordered'}>
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Address</th>
                                    <th>User Type</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {serviceProvider.map((item, index)=>(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.address}</td>
                                        <td>{item.userType}</td>
                                        <td>
                                            <Link to={`service-provider-details/${item.id}`}><button className={'btn btn-sm btn-info'}><i className={'fas fa-eye'}/> </button></Link>
                                            <button className={'btn btn-sm btn-warning'}><i className={'fas fa-edit'}/> </button>
                                            <button className={'btn btn-sm btn-danger ms-1'}><i className={'fas fa-trash'}/> </button>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ServiceProviderList;
