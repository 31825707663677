import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import loader from "../../../Assets/image/loader/ripple.svg";
import Pagination from "react-js-pagination";
import {Modal} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import BASEURL from "../../../Constants";
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

const Service = () => {

    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty(),
    );

    const [service, setService] = useState([])
    const getServices = () => {
        axios.get(BASEURL + '/api/client/service-list').then(res => {
            setService(res.data.data)
        })
    }

    const [input, setInput] = useState({})
    const [serviceDetails, setServiceDetails] = useState([])
    const [errMsg, setErrMsg] = useState([])
    const [isEditModalOpen, setEditModalOpen] = useState(false)
    const [imagePreview, setImagePreview] = useState(null)

    const handleEditModelOpen = (id) => {

        axios.get(BASEURL + '/api/client/service-list/' + id).then(res => {
            setServiceDetails(res.data)
            setInput(prev => ({...prev, service_name: res.data.service_name}))
            setInput(prev => ({...prev, description: res.data.description}))
            setEditModalOpen(true)
        })

    }

    const handleInput = (e) => {
        setInput(prev => ({...prev, [e.target.name]: e.target.value}))
    }

    const handleImageUpload = (e) => {
        let reader = new FileReader()
        let file = e.target.files[0]
        reader.onloadend = () => {
            setImagePreview(reader.result)
            setInput(prev => ({...prev, photo: reader.result}))
        }
        reader.readAsDataURL(file)
    }

    const updateService = (id) => {
        axios.put(BASEURL + '/api/client/service-list/' + id, input).then(res => {
            toast.success(res.data.msg)
            setEditModalOpen(false)
            getServices()
        })
    }

    useEffect(() => {
        getServices()
    }, [])

    return (
        <main>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container-fluid px-4">
                <h1 className="mt-4">Service List</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item"><Link to={'/'}>Dashboard</Link></li>
                    <li className="breadcrumb-item active">Service List</li>
                </ol>
                <div className="my-card">
                    <div className="row">
                        <div className="col-md-12">

                            <table className={'table table-hover table-bordered'}>
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Image</th>
                                    <th>Service Name</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {service.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {item.photo != null?<img src={item.photo} className={'img-fluid'} style={{width: '100px'}} alt={item.service_name}/>:null}
                                        </td>
                                        <td>{item.service_name}</td>
                                        <td>{item.description}</td>
                                        <td>
                                            <button onClick={() => handleEditModelOpen(item.id)}
                                                    className={'btn btn-sm btn-warning'}><i className={'fas fa-edit'}/>
                                            </button>
                                            <button className={'btn btn-sm btn-danger ms-1'}><i
                                                className={'fas fa-trash'}/></button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isEditModalOpen}
                onHide={() => setEditModalOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span>Update Service</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-10 my-2">
                            <label className={'mb-2'}>Service Name
                                <input
                                    placeholder={'Enter Service Name'}
                                    onChange={handleInput}
                                    name={'service_name'}
                                    type={'text'}
                                    className={'form-control'}
                                    defaultValue={input.service_name}
                                />
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <small
                                        className="text-danger validation-error-message"
                                        key={key}>{key == 'service_name' ? val : ''}
                                    </small>
                                ))}
                            </label>
                            <label className={'mb-2'}>Service Description
                                {/*<textarea*/}
                                {/*    placeholder={'Enter Service Name'}*/}
                                {/*    onChange={handleInput}*/}
                                {/*    name={'description'}*/}
                                {/*    className={'form-control'}*/}
                                {/*    defaultValue={input.description}*/}
                                {/*/>*/}

                                 <Editor editorState={editorState} onChange={setEditorState} />

                                {Object.entries(errMsg).map(([key, val]) => (
                                    <small
                                        className="text-danger validation-error-message"
                                        key={key}>{key == 'description' ? val : ''}
                                    </small>
                                ))}
                            </label>

                            <label className={'mb-2'}>Photo
                                <input
                                    placeholder={'Enter Service Name'}
                                    onChange={handleImageUpload}
                                    name={'photo'}
                                    className={'form-control'}
                                    type={'file'}
                                />
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <small
                                        className="text-danger validation-error-message"
                                        key={key}>{key == 'photo' ? val : ''}
                                    </small>
                                ))}
                            </label>
                            <div className="row">
                                <div className="col-md-6">
                                    {imagePreview != null ?
                                        <img src={imagePreview} className={'img-thumbnail'} alt={'Preview'}/> : null
                                    }
                                </div>
                                <div className="col-md-6">
                                    {serviceDetails.photo != null ?
                                        <img src={serviceDetails.photo} className={'img-thumbnail'}
                                             alt={'Preview'}/> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 my-2">
                            <div className="d-grid">
                                <button className={'btn btn-success'} onClick={()=>updateService(serviceDetails.id)}>Update Service</button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </main>
    );
};

export default Service;
