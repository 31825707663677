import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import BASEURL from "../../../Constants";
import {Modal} from "react-bootstrap";
import loader from "../../../Assets/image/loader/ripple.svg";
import Pagination from "react-js-pagination";

const ServiceProviderDetails = () => {

    const [activePage, setActivePage] = useState(1)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [isPriceUpdateModalOpen, setPriceUpdateModalOpen] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [params] = useState(useParams())

    const getOrderList = (pageNumber) => {
        setActivePage(pageNumber)
        setIsLoading(true)
        axios.get(BASEURL + '/api/client/get-service-provider-details/'+params.id+'?page='+pageNumber).then(res => {
            setOrders(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getOrderList()
        getServiceProviderList()
    }, [])

    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
    const [input, setInput] = useState({})
    const [errMsg, setErrMsg] = useState([])

    const handleAssignModal = (id) => {
        setIsAssignModalOpen(true)
        setInput(prev => ({...prev, order_id: id}))
    }
    const [serviceProviderList, setServiceProviderList] = useState([])
    const getServiceProviderList = () => {
        axios.get(BASEURL + '/api/client/get-service-provider-list').then(res => {
            setServiceProviderList(res.data)
        })
    }
    const handleInput = (e) => {
        setInput(prev => ({...prev, assigned_to: e.target.value}))
        console.log(input)
    }
    const assignOrder = () => {
        axios.post(BASEURL + '/api/client/assign-order', input).then(res => {
            toast.success(res.data.msg)
            setIsAssignModalOpen(false)
            getOrderList()
        })
    }

    const [amountInput, setAmountInput] = useState({})
    const handlePriceUpdateModal = (id, price) => {
        setAmountInput(prev => ({...prev, appointment_id: id}))
        setAmountInput(prev => ({...prev, amount: price}))
        setPriceUpdateModalOpen(true)
    }

    return (
        <main>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="container-fluid px-4">
                <h1 className="mt-4">Provider Details</h1>
                <ol className="breadcrumb mb-4">
                    <li className="breadcrumb-item"><Link to={'/'}>Dashboard</Link></li>
                    <li className="breadcrumb-item active">Provider Details</li>
                </ol>
                <div className="my-card">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className={'table table-hover table-bordered table-striped'}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Customer Details</th>
                                        <th>Service Details</th>
                                        <th>Description</th>
                                        <th>Amount & Status</th>
                                        <th>Assigned to</th>
                                        <th>Assigned by</th>
                                        <th>Photo</th>
                                        <th width={'150px'}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {orders.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <small>
                                                    <span className={'text-info'}>Name: {item.name}</span>
                                                    <hr/>
                                                    <span className={'text-success'}>Address : {item.name}</span>
                                                    <hr/>
                                                    <span className={'text-info'}>Phone : {item.phone}</span>
                                                    <hr/>
                                                    <span className={'text-success'}>Email : {item.email}</span>
                                                    <hr/>
                                                    <span className={'text-info'}>Latitude : {item.latitude}</span>
                                                    <hr/>
                                                    <span className={'text-success'}>Longitude : {item.longitude}</span>
                                                </small>
                                            </td>
                                            <td>
                                                <small>{item.serviceData != undefined ? item.serviceData.service_name : null}</small>
                                            </td>
                                            <td>{item.description}</td>
                                            <td>
                                                <small>
                                                    <span className={'text-info'}>  Amount: {item.amount}</span>
                                                    {/*<hr/>*/}
                                                    {/*<span*/}
                                                    {/*    className={'text-success'}>Service Charge: {item.service_charge}</span>*/}
                                                </small><br/>
                                                <small>
                                                <span
                                                    className={'text-danger'}>   Payment Status: {item.payment_status} </span>
                                                    <hr/>
                                                    <span
                                                        className={'text-success'}> Service Status: {item.service_status} </span>
                                                </small>
                                            </td>
                                            <td>
                                                <small>
                                                <span
                                                    className={'text-info'}>  Name: {item.assignedTo != null ? item.assignedTo.name : null} </span>
                                                    <hr/>
                                                    <span
                                                        className={'text-success'}> Mobile: {item.assignedTo != null ? item.assignedTo.mobile : null}</span>
                                                    <hr/>
                                                    <span
                                                        className={'text-info'}> Email: {item.assignedTo != null ? item.assignedTo.email : null} </span>
                                                    <hr/>
                                                    <span
                                                        className={'text-success'}>Address : {item.assignedTo != null ? item.assignedTo.address : null} </span>
                                                </small>
                                            </td>
                                            <td>
                                                <small>
                                    <span
                                        className={'text-success'}>Name: {item.assignedBy != null ? item.assignedBy.name : null}</span>
                                                    <hr/>
                                                    <span
                                                        className={'text-info'}>Mobile: {item.assignedBy != null ? item.assignedBy.mobile : null}</span>
                                                    <hr/>
                                                    <span
                                                        className={'text-success'}>Email: {item.assignedBy != null ? item.assignedBy.email : null}</span>

                                                </small>
                                            </td>
                                            <td>
                                                <img src={item.photo} className={'img-thumbnail'} alt={'image'}
                                                     style={{width: '200px'}}/>
                                            </td>
                                            <td>
                                                <button className={'btn btn-sm btn-info'}><i
                                                    className={'fas fa-eye'}/> Details
                                                </button>
                                                <button onClick={() => handleAssignModal(item.id)}
                                                        className={'btn btn-sm btn-warning mt-1'}><i
                                                    className={'fas fa-edit'}/> Assign
                                                </button>
                                                {item.payment_status != 'COMPLETED' ?
                                                    <button className={'btn btn-success btn-sm mt-1'}
                                                            onClick={() => handlePriceUpdateModal(item.id, item.amount)}>
                                                        <i
                                                            className="fas fa-dollar-sign"/> Update Price
                                                    </button> : null
                                                }
                                                <button className={'btn btn-sm btn-danger mt-1'}><i
                                                    className={'fas fa-trash'}/> Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            {Object.keys(orders).length == 0 && !isLoading ?
                                <div className="loader">
                                    <h6 className={'text-danger text-center'}>No Data Found</h6>
                                </div> : null
                            }
                            {isLoading ?
                                <div className="loader">
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            {totalItemsCount > 20 ?
                                <nav aria-label="Page navigation example">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        // pageRangeDisplayed={5}
                                        onChange={getOrderList}
                                        nextPageText="Next"
                                        prevPageText="Previous"
                                        firstPageText="First"
                                        lastPageText="Last"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </nav> : null
                            }
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isAssignModalOpen}
                onHide={() => setIsAssignModalOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span>Assign Service Provider</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col-md-10 my-2">
                            <label className={'mb-2'}>Select Service Provider
                                <select
                                    placeholder={'Select Service Provider'}
                                    onChange={handleInput}
                                    name={'assigned_to'}
                                    className={'form-select'}
                                    defaultValue={input.assigned_to}
                                >
                                    {serviceProviderList.map((item, index) => (
                                        <option value={item.id} key={index}>{item.name} - {item.email}</option>
                                    ))}
                                </select>
                                {Object.entries(errMsg).map(([key, val]) => (
                                    <small
                                        className="text-danger validation-error-message"
                                        key={key}>{key == 'assigned_to' ? val : ''}
                                    </small>
                                ))}
                            </label>
                        </div>
                        <div className="col-md-7 my-2">
                            <div className="d-grid">
                                <button className={'btn btn-success'} onClick={() => assignOrder()}>Assign Service
                                    Provider
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </main>
    );
};

export default ServiceProviderDetails;
